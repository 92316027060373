

:root {
    --width: 25%;
    --display: flex;

    @include mdStyle {
      --width: 50%;
      --display: grid;
    }
  
    @include mdBelowStyle {
      --width: 100%;
      --display: grid;
    }
  
    @include smStyle {
      --width: 30%;
      --display: grid;
    }
  
    @include xsStyle {
      --width: 40%;
      --display: grid;
    }
  }



.quoteHeader{
    padding: 10px;
    display: var(--display);
    padding-bottom: 20px;
   
    background-color: #fff;
    margin-bottom : 30px;
    border-radius: 10px;
  
    display: flex;
    flex-direction: column;
       justify-content: center;

       align-content: center;

 
    }   

    .dd{
        display: flex;
        flex-direction: row;
           justify-content: space-around;
    
           align-content: space-around;
    
    }
    .spaceQuote{
        margin-top: -20px;
    }

    .quote{
        min-width: 200px;
        margin-right: auto;
          
    }
    .ult{
      
        font-size: 3rem;
        
        letter-spacing: -.5px;
      
     
        width: 120px;
    }
    .ultB{
      
        font-size: 3rem;
        
        letter-spacing: -.5px;
      
     
        width:  100px;
    }
    .seta{
        margin: auto;
        display: inline-block;
        font-size: 1.6rem;
    }

    .codeCbio{
        margin: auto;
        display: inline-block;
        font-size: 1.4rem;
    }
    .box{
        /* margin: 0 20px 0 0;
        padding-top: 10px; */
        /* text-align: center; */
        /* float: left;
        display: inline-block; */
        text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  
    }
    .var{
        margin: 0;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -.6px;
        font-weight: 400;
    }
    
 
    .mini{
        margin: 0;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -.6px;
        font-weight: 400;
    }
    
   

    
    .txt{
        margin: 2px 0 0;
        font-size: 10px;
        font-weight: 500;
        line-height: 16px;
        color: rgba(51,51,51,.65);
        text-transform: uppercase;

    }

    .at{
       
        font-size: 8px;
        font-weight: 200;
        line-height: 16px;
        color: rgba(51,51,51,.65);
        text-transform: uppercase;
        text-align: right;
        margin-top: 10px;
        margin-bottom: -14px;
    }
       
    .longo{
        text-align: center;
        margin: 2px 0 0;
        font-size: 13px;
        font-weight: 500;
        line-height: 16px;
        color: rgba(51,51,51,.65);
        text-transform: uppercase;
        padding-bottom: 10px;

    }




.fontGreen{
    color: #0ea600;
}
.fontRed{
    color: red;
}
.fontGrey{
    color: lightgrey;
}

.dados{
    display: flex;
    flex-direction: row;
    
    justify-content: center;

    align-content: center;
  
/*    
    grid-gap: 5px;  
    grid-template-columns: repeat(auto-fit, 60px);
    grid-template-rows: repeat(2 60px);   */
    
}

.qs{
/*    
    font-size: 3rem;
    
    letter-spacing: -.5px;
   
    display: inline-block;  
    margin-right: 0vw;
    float:right; */
    
}
.resetBox{ 
    margin: 0 13px 0 0;
        padding-top: 10px;
        text-align: center;
        float: left;
        display: inline-block;
    }

    .resetQuote{
        min-width: 150px;
        margin-right: auto;
          
        
      }   