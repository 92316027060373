

.header {
  position: relative;
  background: #5b6f2c;
  overflow: hidden;
margin-top: 3px;

  height: 170px;
  margin-bottom: -20px;
}
.header img {
  padding: 50px;
  position: relative;
  z-index: 2;
  color: #fff;
}
/* You could use :after - it doesn't really matter */
.header:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.5;
  background-image: url('https://assets.datagro.com/imagens/Header_limpo2.png')  ;
  background-repeat: no-repeat;
  /*background-position: 90% 50%; */
  background-size: cover;
}















.bodyCbio{
  background-color:#eaeaea;
  max-width: 1400px;
  margin: auto;
 
}


.meta{
  background: #ffff;
  height: 80px;
  margin-right: 15px;
  border-radius: 5px;
  box-shadow: 8px 4px 9px 0px rgba(193,193,193, 0.75);
}

.metaD{
 padding-top:20px;
  flex-direction: 'row';
  justify-content: 'flex-start';
}

.cumprimento{
  margin-top: 15px;
  background: #ffff;
  height: 200px;
 
  margin-right: 15px;
  border-radius: 5px;
  box-shadow: 8px 4px 9px 0px rgba(193,193,193, 0.75);
  padding-top: 30px;
}

.newsSidebar {
  position: relative;
  background: #5b6f2c;
  overflow: hidden;
  margin-right: 15px;
  margin-top: 15px;
  border-radius: 5px;
  box-shadow: 8px 4px 9px 0px rgba(193,193,193, 0.75);
  height: 970px;
  margin-bottom: 40px;
}
.newsSidebar h4 {
 margin-top: 20px;
  position: relative;
  z-index: 2;
  color: #fff;
  text-align: center;
}

 .newsList{
  padding: 20px;
  position: relative;
  z-index: 2;
  color: #fff;
  text-align: center;
}

/* You could use :after - it doesn't really matter */
.newsSidebar:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.2;
  background-image: url('https://assets.datagro.com/imagens/News.png');
  background-repeat: no-repeat;
  /* background-position: 50% 0; */
  background-size: cover;
}


.footerCbio{

  height: 120px;
  background-color: #fff;

}


.titleRecap{
  background-color: #000;
  border-radius: 5px;
  font-size:22px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  margin-top: 30px;
margin-bottom: -10px;
font-weight: bold;
}
.titleVenda{
  background-color: #576819;
  border-radius: 5px;
  font-size:22px;
  color: #fff;
  text-align: center;
  padding: 10px;
 
margin-bottom: -10px;
font-weight: bold;
  
}


.titleCompra{
  background-color: #95c422;
  border-radius: 5px;
  font-size:22px;
  color: #fff;
  text-align: center;
  padding: 10px;
  
margin-bottom: -10px;
font-weight: bold;
 
}




.tableCbio {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.tableCbio td, .tableCbio th {
 
  padding: 8px;
}

.tableCbio tr:nth-child(even){background-color: #ffffff;}

.tableCbio tr:hover {background-color: #ddd;}

.tableCbio th {
  
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);

 
  padding-bottom: 5px;
  text-align: center;
  background-color: #eaeaea;
  color: black;
}


.tilteChart{
  color: black;
  font-size:18px;
  margin-left: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;


}


.indicador{
  display: flex;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  align-items: center;
    font-size: 1.5em;
    font-weight: 300;
    color: #555;
}


.cumprimentoTitle{
  background-color: #000;
  border-radius: 10px;
  font-size:14px;
  color: #fff;
  text-align: center;
  padding: 5px;
 
}


.read{
  background-color: #c5470f;
  border-radius: 5px;
  font-size:17px;
  color: #fff;
  text-align: center;
  padding: 7px;
  margin-bottom: 10px;

}


.read:hover {
  color: #95c422;
}

.link{
  text-decoration: none;
}


.mainSidebar {
  float: right;
  width: 350px;

}

.content {
  float: left;
  padding-right: 370;
  width: calc(100% - 370px);
}



.carteiraSantander{
  background: #ffff;
   height: 120px; 
   display: flex;
flex-direction: column;
   justify-content: center;
   align-items: center;
  border-radius: 5px;
  color: red;
  font-weight: bold;

  box-shadow: 8px 4px 9px 0px rgba(193,193,193, 0.75);

}


.acessar{
  background-color: red;
  border-radius: 10px;
  font-size:12px;
  color: #fff;
  text-align: center;
  margin-top: 10px;
  padding: 5px; 
  padding-left: 15px;
  padding-right: 15px;

}