.TopBar {
  background-color: #000;
  height: 30px;
  color: #fff;
  font-size: 14px;
}

.TopBar nav{
   
}
.menu {

  list-style: none;
  border: 1px solid #000;
  /* float:left; */
 
}

.menu li {
  position: relative;
  float: left;
  border-right: 1px solid #000;
}

.menu li a {
  color: #fff;
  text-decoration: none;
  padding: 0px 10px;
  display: block;
}

.menu li a:hover {
  z-index: 200;
  background: #333;
  color: #fff;
  /* -moz-box-shadow: 0 3px 10px 0 #ccc;
  -webkit-box-shadow: 0 3px 10px 0 #ccc; */
  text-shadow: 0px 0px 5px #fff;
}

.menu li ul {
  position: absolute;
  
  left: 0;
  background-color: #000;
  display: none;
 
}

.menu li:hover ul,
.menu li.over ul {
  display: block;
  z-index: 999;
}

.menu li ul li {
  border: 1px solid #000;
  display: block;
  margin-left: -30px;
  width: 250px;
  margin-top:2px;

}

.hrr{
    /* margin-left: -40px; */
     height: 2px; 
     border-width: 0px; 
     color: gray;
      background-color: gray;
      margin-top: -1px;
      margin-bottom:1px;
}
