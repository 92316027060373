.title{
  color: #fff;
  
  font-size:18px;
  text-align: left;
  text-decoration: none;
}


.link{
  text-decoration: none;
}

.title:hover {
  color: #95c422;
}


.data{
  font-size:14px;
  text-align:left;
  color: #ccc;
  margin-bottom: 20px;
}